<app-navbar></app-navbar>
<div class="container" *ngIf="popItemData">
  <div class="row">
    <div class="row g-0 mainRow headline add">
      <div class="col-12 pt-5">
        <div class="flexDiv" style="justify-content: space-between">
          <div class="flexDiv" *ngIf="popItemData">
            <!--          <i class="fas fa-map-marker-alt" id="popName"></i>-->
            <h1 class="fao_mobile_title_large ">&nbsp;{{deleteSpace(popItemData.title)}}</h1>
          </div>
        </div>
      </div>
    </div>
    <h2 class="sub_description Font-family">{{popItemData.sub_title}}</h2>
  </div>

  <div class="row mb-3">
    <div class="col-7">
      <ngb-carousel>
        <ng-container *ngFor="let item of popItemData.images; index as i ">
          <ng-template ngbSlide>

            <div class="picsum-img-wrapper">
              <img [src]=item.full_url alt="Random second slide" width="100%" height="540"
                   style="border-radius: 12px" alt="{{popItemData.title }}" title="{{ popItemData.title }}"/>

              <!--<p class="sub_description">{{popItemData.sub_description}}</p>-->
            </div>
            <div class="carousel-caption"></div>

          </ng-template>
        </ng-container>
      </ngb-carousel>
    </div>
    <div class="col-5  mb-5">
      <div class="row justify-content-center ">
        <p class="sub-title text-center Font-family">{{popItemData.sub_description}}</p>
        <section>
          <div class="col-lg-1 pt-4 order-lg-first ">
            <div class="sticky-content">
              <div class="social-icons social-icons-colored social-icons-vertical">
                <div class="icon-share">
                  <span class="social-label Font-family">SHARE:</span>
                  <a class="social-icon image-item" (click)="cop()" target="_blank">
                    <img *ngIf="!copied" title="copy" class="copy" alt="copy"
                         src="./assets/newIcon/copy-blue-icon.svg"/>
                    <img *ngIf="copied" title="copied" class="copied" alt="copied"
                         src="./assets/newIcon/copied.png"/>
                  </a>
                  <!-- <a (click)="fb()" class="social-icon social-facebook" title="Facebook"><i
                      class="icon-facebook-f"></i></a>
                  <a (click)="tw()" class="social-icon social-twitter" title="Twitter"><i class="icon-twitter"></i></a>
                  <a (click)="in()" class="social-icon social-linkedin" title="Linkedin"><i
                      class="icon-linkedin"></i></a> -->
                  <a (click)="fb()" class="social-icon social-facebook" title="Facebook">
                    <img title="facebook icon" alt="facebook icon" src="assets/icons/287739_facebook_icon.svg"></a>
                  <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                    <img title="twitter icon" alt="twitter icon" src="assets/icons/294709_circle_twitter_icon.svg"></a>
                  <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                    <img title="Linked In" alt="Linked In" src="assets/icons/317750_linkedin_icon.svg"></a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="col-12">
      <div class="portfolio-description  pt-4 readMore mb-5 Font-family">
        <!--          <app-read-more *ngIf="display && popItemData.description"  [innerHtml]="popItemData.description" maxLength="30"-->
        <!--&lt;!&ndash;            class="aboutText Font-family"></app-read-more>&ndash;&gt;-->
        <p class="aboutText Font-family" [innerHtml]="popItemData.description"></p>
      </div>
    </div>
  </div>


  <!--     start rating     -->
  <div class="row mt-4">
    <div class="col-7">
      <app-show-rate [reviewData]="reviewData"></app-show-rate>
      <app-add-reviews [cityId]="blogslug" [keyId]="'blog_id'" (addReview)="setReview($event)"></app-add-reviews>

    </div>

    <div class="col-5 offerSlide ">
      <app-point-slider-offers></app-point-slider-offers>
    </div>
  </div>

  <!--     end rating     -->
</div>
<div class="container">
  <!--          show reviews            -->
  <div class="">
    <!--      Temporary merge branch 1-->
    <div class=" col-7 handel-pos mt-0 pt-0">
      <button class="fao_Show_btn_large " (click)="showReviews()" *ngIf="!showRev">Show All Reviews</button>
      <button class="fao_Show_btn_large " (click)="showReviews()" *ngIf="showRev">Hide All Reviews</button>
    </div>
    <div class="all-reviews">
      <div class="row">
        <owl-carousel-o *ngIf="showRev" [options]="customOptions" class="d-flex justify-content-center mt-5">
          <ng-container *ngFor='let item of reviewData.reviews ; let i = index'>
            <ng-container>
              <ng-template carouselSlide>
                <app-share-reviews [data]="item" [image]="popItemData.image"></app-share-reviews>
              </ng-template>
            </ng-container>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>

    <!--         show video   -->
    <section class="my-5">
      <div class="mt-5 mx-auto">
        <app-show-video></app-show-video>
      </div>
    </section>

    <!--/////////////////////// Start Card Slider////////////-->
    <div class="container">
      <div class="row my-5 ml-5">
        <h2 class="fao_mobile_title_large mt-5 Font-family text-center "> MORE ON Airlines </h2>
        <div class="horizontalLine mb-5"></div>
        <!--  <div class="horizontalLine"></div>-->
        <owl-carousel-o *ngIf="data" [options]="customOptions" class="d-flex justify-content-center">
          <ng-container *ngFor="let item of data; let i = index">
            <ng-container *ngIf="i<data.length && i>0 ">
              <ng-template carouselSlide>
                <div class="col col-md-12 col-lg-12 hover">
                  <div class="offer card-body">
                    <div class="offer-img">
                      <a [routerLink]="['/blog-description/', item.slug]">
                        <img src="{{item.image}}" alt="{{ item.title }}" class="rounded w-100" height="250px"/>
                      </a>
                      <div class="text-center  ">
                        <a style="text-decoration: none;" [routerLink]="['/blog-description/', item.slug]">
                          <h5 class="fao_mobile_title_meduim custom_title mt-3">
                            {{ item.title |slice:0:50 }}
                          </h5>
                        </a>
                      </div>
                    </div>
                    <div style="
                      background-color: #ddd4d4;
                      height: 1px;
                      width: 95%;
                      margin: auto;
                    "></div>
                    <div>
                      <p class="fao_mobile_prgraph">
                        {{ item.description |slice:0:70 }}
                      </p>
                      <div class="center-class">
                        <button class="fao_des_btn_med my-4" [routerLink]="['/blog-description/', item.slug]">
                          MORE DETAILS
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </owl-carousel-o>
      </div>

    </div>
  </div>
</div>
<app-footer></app-footer>
